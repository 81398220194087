<template>
  <div class="type-coupon">
    <s-loading
      :show="loading"
      :container-cover-style="{
        'background-color': '#fff',
      }"
      :type="page ? 'newpage' : 'curpage'"
    />
    <AddOnItem
      v-if="couponInfo.coupon_code"
      :page="page"
      :drawer-config="drawerConfig"
      :language="language"
      :abt-info="abtInfo"
      :query-info="{
        ...$attrs.queryInfo,
        ...anotherInfo,
      }"
      :type="$attrs.type"
      :sa-info="$attrs.saInfo"
      :config="config"
      @update-cart="handleUpdateCart"
    >
      <template slot="header">
        <transition
          name="header-animate"
        >
          <component
            :is="headerName"
            v-if="!isFullScreen"
            :page="page"
            :language="language"
            :drawer-config="drawerConfig"
            :coupon-info="couponInfo"
            :coupon-info-list="couponInfoList"
            @change-coupon="onChangeCoupon"
            @close="$emit('close')"
          />
          <template v-else>
            <FullScreenHead
              type="coupon"
              :tips="language.SHEIN_KEY_PWA_27301"
              :isB5="headerName === 'BlackfridayHead'"
            >
              <template #right>
                <CouponMoreReference
                  v-if="isMultiple"
                  level
                  modal
                  :language="language"
                  :drawer-config="drawerConfig"
                  :coupon-info="couponInfo"
                  :coupon-list="couponInfoList"
                  @click-coupon="onClickCoupon"
                />
              </template>
            </FullScreenHead>
          </template>
        </transition>
      </template>
      <template #footer="{ viewCart }">
        <BusinessCartFoot 
          v-if="isBusinessCartBottom"
          ref="BusinessCartFoot"
          type="coupon"
          :promotion-id="couponInfo.coupon_code"
          :state="$attrs.saInfo && $attrs.saInfo.state"
          :add-on-tips="addOnTips"
          :add-on-status="addOnStatus"
          @checkoutClick="viewCart({ couponInfo })"
          @cartUpdated="hanldeBusinessCartUpdated"
        />
        <component
          :is="footerName"
          v-else
          :language="language"
          :coupon-info="couponInfo"
          :config="config"
          @close="$emit('close')"
          @back="viewCart({ couponInfo })"
        />
      </template>
    </AddOnItem>
    <NoNetworkEmpty
      :is-empty="!couponInfo || (couponInfo && !couponInfo.coupon_code)"
      :language="language"
      @online-changed="handleOnlineStatus"
    />
  </div>
</template>

<script>
import { cloneDeep } from '@shein/common-function'
import { getCouponInfo } from '../../utils/fetcher.js'
import { formatCoupon  } from '../../utils/index.js'
import { EventFetchError } from '../../utils/event.js'
import { handleCouponSort, handleCouponFilter } from '../../utils/index.js'
import { EventExposeAddCart, EventUpdateCartChildren, EventListReset, EventProgressUpdate, EventSetProgressPromise } from '../../utils/event.js'
import { getProgressDesc, getProgressData, isCouponProgressUp } from './utils.js'
import { mapState } from 'vuex'
import { template } from '@shein/common-function'

import BusinessCartFoot from 'public/src/pages/common/addOnItem/comps/base/BusinessCartFoot.vue'
import NoNetworkEmpty from 'public/src/pages/cartNew/components/offline/NoNetworkEmpty.vue'
const { IS_RW } = gbCommonInfo

export default {
  name: 'TypeCoupon',
  components: {
    AddOnItem: () => import(/* webpackChunkName: "add_on_item" */'../AddOnItem.vue'),
    CouponHeader: () => import(/* webpackChunkName: "add_on_item" */'./CouponHeader.vue'),
    CouponFooter: () => import(/* webpackChunkName: "add_on_item" */'./CouponFooter.vue'),
    NormalHead: () => import(/* webpackChunkName: "add_on_item" */'./normal/Head.vue'),
    MultipleHead: () => import(/* webpackChunkName: "add_on_item" */'./multiple/Head.vue'),
    NormalFoot: () => import(/* webpackChunkName: "add_on_item" */'./normal/Foot.vue'),
    FullScreenHead: () => import(/* webpackChunkName: "add_on_item" */'public/src/pages/common/addOnItem/comps/base/FullScreenHead.vue'),
    ProgressBar: () => import(/* webpackChunkName: "add_on_item" */'public/src/pages/common/addOnItem/comps/base/ProgressBar.vue'),
    CouponMoreReference: () => import(/* webpackChunkName: "add_on_item_coupon_more" */'./multiple/couponMore/Reference.vue'),
    BusinessCartFoot,
    NoNetworkEmpty,
  },
  props: {
    page: Boolean,
    drawerConfig: {
      type: Object,
      default: () => ({}),
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    secondaryCoupon: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      loading: true,
      otherAddCartNum: 0,
      lastCouponInfo: {}, // 上一次券信息
      singleCouponInfo: {}, // 单券信息
      selectCouponInfo: {}, // 多券中选择的券信息
      couponInfoList: [], // 多券信息
      originCouponInfo: {}, // 记录初始券信息，主要用于埋点
      originCouponInfoList: [], // 多券 记录初始券信息，主要用于埋点
      isShowAnimation4: false, // 展示过动效 4
      isShowAnimation5: false, // 展示过动效 5
      isOnline: true,
    }
  },
  computed: {
    ...mapState(['abtInfo', 'language', 'progressDataPromise', 'isFullScreen']),
    isCouponAdd() {
      return this.$attrs.saInfo.activity_from === 'coupon_add'
    },
    // 单券的 coupon code
    couponCode() {
      return this.$attrs?.coupon || this.singleCouponInfo.coupon_code
    },
    couponInfo() { // 当前券信息
      if (this.isMultiple) {
        return this.selectCouponInfo
      }
      return this.singleCouponInfo
    },
    isMultiple() {
      if (this.isCouponAdd) return false
      return !!this.couponInfoList.length
    },
    isOriginMultiple() {
      if (this.isCouponAdd) return false
      return !!this.originCouponInfoList.length
    },
    anotherInfo() {
      const { direct_tag, return_tag, _data } = this.couponInfo
      const jsonRuleId = this.abtInfo?.addnewhotJson?.param || {}
      return {
        goodsPrice: ((_data?.activeRule?.need_price?.usdAmount || 0) * 100).toFixed(0), // 凑单差额价格(美分)
        includeTspId: direct_tag?.map((v) => v.return_tagid).join(','),
        excludeTspId: return_tag?.map(item => item.return_tagid).join(','),
        jsonRuleId,
        location: 'addnewhotJson',
      }
    },
    headerName() {
      if (this.isCouponAdd) {
        return 'CouponHeader'
      }
      if (this.isMultiple) return 'MultipleHead'
      return 'NormalHead'
    },
    footerName() {
      if (this.isCouponAdd) {
        return 'CouponFooter'
      }
      return 'NormalFoot'
    },
    isBusinessCartBottom() {
      if (this.isCouponAdd || IS_RW) return false
      const { cartadd_coupon_bottom_style } = this.abtInfo?.CartaddCouponBottomStyle?.param || {}
      return ['A', 'B', 'C'].includes(cartadd_coupon_bottom_style) && !this.config.isCloseBusinessCartEntry
    },
    addOnTips() {
      return getProgressDesc(this.couponInfo, this.language)
    },
    addOnStatus() {
      return this.couponInfo?._data?.is_satisfied
    },
    
  },
  mounted() {
    this.cartUpdated({ first: true })

    EventUpdateCartChildren.subscribe({
      callback: () => {
        if (
          this.isOriginMultiple &&
          this.originCouponInfoList[0].coupon_code !== this.couponInfo.coupon_code
        ) {
          this.otherAddCartNum += 1
        }
      }
    })
  },
  methods: {
    /**
     * 获取弹窗关闭载荷数据
     */
    getClosePayload() {
      return this.couponInfo
    },
    onChangeCoupon(couponInfo) {
      // if (this.selectCouponInfo.coupon_code === couponInfo.coupon_code) return
      this.selectCouponInfo = couponInfo
      EventListReset.notify({ reset: true })
      this.couponUpdate()
      this.$toast(this.language.SHEIN_KEY_PWA_30085)
      EventExposeAddCart.notify()
    },
    onClickCoupon(data) {
      switch (data.type) {
        case 'add':
        case 'check':
          this.onChangeCoupon(data.coupon)
          daEventCenter.triggerNotice({
            daId: '1-8-4-23',
            extraData: {
              position: 'button',
              coupon: data.coupon,
            }
          })
          break
        default:
          console.error('type is invalid')
          break
      }
    },
    // 单券调用
    async getCoupon(first) {
      try {
        if (first) this.loading = true
        const res = await getCouponInfo({ coupon_codes: [this.couponCode] })
        const coupon_diff = res?.info?.coupon_diff?.[0]

        this.lastCouponInfo = cloneDeep(this.singleCouponInfo)

        this.singleCouponInfo = formatCoupon(coupon_diff)

        if (first) this.originCouponInfo = cloneDeep(this.singleCouponInfo)

        if (res.code != 0) {
          EventFetchError.notify({
            code: res.code,
            msg: res.tips || res.msg,
          })
        }
      } finally {
        if (first) this.loading = false
      }
    },
    // 多券调用
    async getCouponList({ first = false, isUpdate = false } = {}) {
      try {
        if (first) this.loading = true
        const coupon_codes = []
        if (this.$attrs?.coupon) coupon_codes.push(this.$attrs.coupon)
        if (this.secondaryCoupon.length) coupon_codes.push(...this.secondaryCoupon)
        const res = await getCouponInfo({
          coupon_codes,
          error_type: 1,
        })

        let couponInfoList = res?.info?.coupon_diff?.map?.(item => formatCoupon(item))
        if (isUpdate) {
          // 只过滤无效的券
          couponInfoList = handleCouponFilter({
            couponListInfo: couponInfoList,
            prevCouponListInfo: this.couponInfoList,
          })
        } else {
          couponInfoList = handleCouponSort({
            primaryCoupon: this.$attrs?.coupon,
            secondaryCoupon: this.secondaryCoupon,
            isSorted: this.config?.couponIsSorted || false,
            couponListInfo: couponInfoList,
          })
        }

        // TODO debugger
        // const n = Math.random()
        // console.log('%c Math.random()', 'font-size:20px', n)
        // if (n < 0.3) {
        //   couponInfoList = []
        // } else if (n < 0.6) {
        //   couponInfoList.splice(1, 1)
        // }

        // 没有有效的券
        if (res.code == 0 && couponInfoList.length === 0) {
          res.code = '500110'
          res.msg = this.language.SHEIN_KEY_PWA_29974
        }

        // 只有 1张 有效的券
        if (couponInfoList.length === 1) {
          this.singleCouponInfo = couponInfoList[0]
          this.selectCouponInfo = {}
          this.couponInfoList = []
          return
        }

        this.couponInfoList = couponInfoList

        this.lastCouponInfo = cloneDeep(this.selectCouponInfo)

        if (Object.keys(this.selectCouponInfo).length) {
          // 找不到券就默认取第一张 TODO swiper index 异常
          const target = this.couponInfoList.find(f => f.coupon_id === this.selectCouponInfo.coupon_id) || this.couponInfoList[0]
          this.selectCouponInfo = target
        } else {
          this.selectCouponInfo = this.couponInfoList[0]
        }

        if (first) this.originCouponInfoList = cloneDeep(this.couponInfoList)

        if (res.code != 0) {
          EventFetchError.notify({
            code: res.code,
            msg: res.tips || res.msg,
          })
        }
      } finally {
        if (first) this.loading = false
      }
    },
    async cartUpdated({ first = false } = {}) {
      if (this.isMultiple || first && !this.isCouponAdd && this.secondaryCoupon.length) {
        // 非首次调用，只过滤不排序
        await this.getCouponList({ first, isUpdate: !first }) // 重新拿券数据
      } else {
        await this.getCoupon(first) // 重新拿券数据
      }
      const progressData = getProgressData(this.couponInfo)
      
      if (first) {
        EventProgressUpdate.notifySync(progressData)
      } else {
        this.progressDataPromise?.resolve?.({
          progressData,
          bubbleText: this.getBubbleText(),
          noAnimation: this.isCouponAdd
        })
        if (
          this.page && // 如果是 page
          window.SaPageInfo?.page_param &&
          this.lastCouponInfo._data.is_satisfied !== this.couponInfo._data.is_satisfied // is_satisfied 变化
        ) {
          window.SaPageInfo.page_param.is_satisfied = this.couponInfo._data.is_satisfied
        }
      }
    },
    handleUpdateCart() {
      this.cartUpdated(false)
      this.$refs.BusinessCartFoot?.refetchCarts()
    },
    async couponUpdate() {
      if (this.isMultiple) {
        // 切券 只过滤不排序
        await this.getCouponList({ isUpdate: true }) // 重新拿券数据
      } else {
        await this.getCoupon() // 重新拿券数据
      }
      const progressData = getProgressData(this.couponInfo)
      EventProgressUpdate.notifySync(progressData)
    },
    getBubbleText() {
      let { activeRuleIndex: lastActiveRuleIndex } = this.lastCouponInfo._data
      let { activeRule, activeRuleIndex } = this.couponInfo._data
      const progressData = getProgressData(this.couponInfo)
      const lastProgressData = getProgressData(this.lastCouponInfo)
      if (!isCouponProgressUp(progressData, lastProgressData)) return '' // 判断是否凑单进度提升
      
      // 如果上次已经凑满所有档位
      if (lastActiveRuleIndex == -1) return ''
      // 本次凑满 or 档次提升
      if (activeRuleIndex == -1 || activeRuleIndex > lastActiveRuleIndex) {
        // 动效 5 只展示一次        
        if (this.isShowAnimation5) return ''
        this.isShowAnimation5 = true
        return this.language.SHEIN_KEY_PWA_27318
      }
      if (this.isShowAnimation4 || this.isShowAnimation5) return ''
      this.isShowAnimation4 = true
      return activeRuleIndex == 0 ? template(activeRule.need_price.amountWithSymbol, this.language.SHEIN_KEY_PWA_27316) : this.language.SHEIN_KEY_PWA_27317
    },
    getSaExtraData() {
      const isUpgrade = (origin, curr)=> {
        let originLevel = origin?._data?.activeRuleIndex || 0
        let currLevel = curr?._data?.activeRuleIndex || 0
        // 当值为 -1 时，代表券已凑满，改到无限大
        if (originLevel === -1) originLevel = 999
        if (currLevel === -1) currLevel = 999
        return currLevel > originLevel ? 1 : 0
      }

      let coupon_change
      let other_coupon_change
      let is_satisfied_all

      // 如果 originCouponInfoList 有值, 说明用户第一次打开的是多券凑单
      // 后续 券失效 变为 单券凑单 也应该上报 多券凑单 的埋点信息
      if (this.isOriginMultiple) {
        // 多券凑单埋点上报
        const originCouponInfo = this.originCouponInfoList[0]
        const currFirstCoupon = this.couponInfoList.find(item => item.coupon_code === originCouponInfo.coupon_code)
        const currOtherCoupon = this.couponInfoList.filter(item => item.coupon_code !== originCouponInfo.coupon_code)
        coupon_change = isUpgrade(originCouponInfo, currFirstCoupon)
        other_coupon_change = currOtherCoupon.some(curr => isUpgrade(this.originCouponInfoList.find(origin => origin.coupon_code === curr.coupon_code), curr)) ? 1 : 0
        is_satisfied_all = this.couponInfoList.map((v) => `${v.coupon_code}_${v._data?.is_satisfied}`)?.join('`')
      } else {
        // 单券凑单埋点上报
        coupon_change = isUpgrade(this.originCouponInfo, this.couponInfo)
        other_coupon_change = '-'
        is_satisfied_all = '-'
      }
      return {
        promotion_code: this.isOriginMultiple ? this.couponInfoList.map(m => m.coupon_code).join(',') : this.couponCode,
        is_satisfied: this.couponInfo?._data?.is_satisfied,
        coupon_change,
        other_coupon_change,
        other_add_cart_number: this.isOriginMultiple ? this.otherAddCartNum : '-',
        is_multiple_coupons: this.isOriginMultiple ? 'multiple' : 'single',
        is_satisfied_all,
      }
    },
    async hanldeBusinessCartUpdated(info) {
      if (['updateQuantity', 'delete', 'modify', 'updateAttr'].includes(info?.action)) {
        EventSetProgressPromise.notify()
        this.cartUpdated(false)
        this.$emit('businessCartUpdated')
      }
    },
    handleOnlineStatus(val) {
      this.isOnline = val
    },
  },
}
</script>

<style lang="less" scoped>
.type-coupon {
  height: 100%;
  .header-animate-enter-active,.header-animate-leave-active { /* stylelint-disable-line */
    transition: all .2s;
  }
  .header-animate-enter,
  .header-animate-leave-to { /* stylelint-disable-line */
    height: 0;
    opacity: .6;
  }
}
</style>
